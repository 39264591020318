<script setup>
import { defineExpose, onMounted, ref } from 'vue'
import { useViewEditInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/viewEditInfoInventory.store'
import { storeToRefs } from 'pinia'

// Variables
const viewEditInfoInventoryStore = useViewEditInfoInventoryStore()
const { _listTypeDevices, _listBrands, _listModels, _listLocations, _listOwners, _listStatus } = storeToRefs(viewEditInfoInventoryStore)
const displayModal = ref(false)
const activeIndex = ref(0)

// Funcion para abrir el modal
const openModal = async (indexActive) => {
  displayModal.value = true
  activeIndex.value = indexActive
}

// Funcion para cerrar el modal
const closeModal = async () => {
  displayModal.value = false
}

// Funcion para cargar la información de las tablas
const loadData = async (index) => {
  activeIndex.value = index
  switch (index) {
    case 0:
      await viewEditInfoInventoryStore._actionListTypeDevices()
      break
    case 1:
      await viewEditInfoInventoryStore._actionListBrands()
      break
    case 2:
      await viewEditInfoInventoryStore._actionListModels()
      break
    case 3:
      await viewEditInfoInventoryStore._actionListLocations()
      break
    case 4:
      await viewEditInfoInventoryStore._actionListOwners()
      break
    case 5:
      await viewEditInfoInventoryStore._actionListStatus()
      break
  }
}

// Expose
defineExpose({
  openModal
})

// Hook
onMounted(async () => {
  console.log('onMounted modal info...')
  await loadData(activeIndex.value)
})
</script>

<template>
  <Dialog
    v-model:visible="displayModal"
    modal
    maximizable
    dismissableMask
    @hide="closeModal"
    :breakpoints="{'960px': '95vw'}"
    :style="{width: '40vw'}"
    :pt="{
      header: {
        style: 'padding: 1rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold">
        <i class="pi pi-info-circle mr-2 text-xl"></i>
        <h4>Información</h4>
      </div>
    </template>

    <!-- Body del modal -->
    <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 px-2">
      <div class="col-span-1 sm:col-span-12 md:col-span-12">
        <p>
          A continuación puede ver la información de los tipos de dispositivos, marcas, modelos, ubicaciones, propietarios y estados que actualmente se encuentran registrados en el sistema.
        </p>
        <TabView
          :scrollable="true"
          @update:activeIndex="loadData"
          :pt="{
            panelContainer: {
              style: 'padding: 0;'
            }
          }"
        >
          <TabPanel header="Tipos dispositivos">
            <div v-if="activeIndex === 0">
              <!-- Tabla de dispositivos -->
              <DataTable
                :value="_listTypeDevices"
                class="p-datatable-sm text-xs"
                stripedRows
                scrollable
                scrollHeight="400px"
                sortField="id"
                :sortOrder="1"
              >
                <Column field="id" header="ID" sortable />
                <Column field="devicename" header="Nombre" sortable />
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel header="Marcas">
            <div v-if="activeIndex === 1">
              <!-- Tabla de marcas -->
              <DataTable
                :value="_listBrands"
                class="p-datatable-sm text-xs"
                stripedRows
                scrollable
                scrollHeight="400px"
                sortField="id"
                :sortOrder="1"
              >
                <Column field="id" header="ID" sortable />
                <Column field="brandname" header="Nombre" sortable />
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel header="Modelos">
            <div v-if="activeIndex === 2">
              <!-- Tabla de modelos -->
              <DataTable
                :value="_listModels"
                class="p-datatable-sm text-xs"
                stripedRows
                scrollable
                scrollHeight="400px"
                sortField="id"
                :sortOrder="1"
              >
                <Column field="id" header="ID" sortable />
                <Column field="modelname" header="Nombre" sortable />
                <Column field="idbrand" header="ID Marca" sortable />
                <Column field="brandname" header="Marca" sortable />
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel header="Ubicaciones">
            <div v-if="activeIndex === 3">
              <!-- Tabla de ubicaciones -->
              <DataTable
                :value="_listLocations"
                class="p-datatable-sm text-xs"
                stripedRows
                scrollable
                scrollHeight="400px"
                sortField="id"
                :sortOrder="1"
              >
                <Column field="id" header="ID" sortable />
                <Column field="name" header="Nombre" sortable />
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel header="Propietarios / Dueños">
            <div v-if="activeIndex === 4">
              <!-- Tabla de dueños -->
              <DataTable
                :value="_listOwners"
                class="p-datatable-sm text-xs"
                stripedRows
                scrollable
                scrollHeight="400px"
                sortField="id"
                :sortOrder="1"
              >
                <Column field="id" header="ID" sortable />
                <Column field="ownername" header="Nombre" sortable />
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel header="Estados">
            <div v-if="activeIndex === 5">
              <!-- Tabla de estados -->
              <DataTable
                :value="_listStatus"
                class="p-datatable-sm text-xs"
                stripedRows
                scrollable
                scrollHeight="400px"
                sortField="id"
                :sortOrder="1"
              >
                <Column field="id" header="ID" sortable />
                <Column field="statusname" header="Nombre" sortable />
              </DataTable>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>

    <!-- Footer del modal -->
    <template #footer>
      <div class="flex justify-end">
        <Button
          label="Cerrar"
          icon="pi pi-times"
          @click="closeModal"
          class="p-button-danger"
        />
      </div>
    </template>
  </Dialog>

</template>

<style scoped>

</style>
