<script setup>
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { error } from '@/libs/mensajes'
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2'
import FilePond from '@/apps/dailyTech/inventario-ti/views/cargaMasiva/components/uploadFilePond/index.vue'
import ModalInfo from '@/apps/dailyTech/inventario-ti/views/cargaMasiva/components/modalInfo.vue'
import { useToast } from 'primevue/usetoast'
import Icon from '@/components/Icon/index.vue'
import { FilterMatchMode } from 'primevue/api'

const router = useRouter()
const toast = useToast()
const _InventoryService = ref(new InventoryService())
const attachFile = ref(null)
const listResults = ref([])
const listColumns = ref([])
const listErrors = ref([])
const listErrorsColumns = ref([])
const dataInventorySave = ref([])
const refModalInfo = ref()
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// Funcion para abrir el modal de información
const openModalInfo = (indexActive) => {
  refModalInfo.value.openModal(indexActive)
}

// Funcion para descargar plantilla excel
const handleDownloadXls = async () => {
  await _InventoryService.value.downloadTemplate().then((url) => {
    window.open(url, '_blank')
  }).catch((err) => {
    console.error(err.message)
    error(err.response.data.message)
  })
}

// Funcion para convertir columnas a letras
const columnToLetter = (column) => {
  let temp
  let letter = ''
  while (column > 0) {
    temp = (column - 1) % 26
    letter = String.fromCharCode(temp + 65) + letter
    column = (column - temp - 1) / 26
  }
  return letter
}

/* Capturar el adjunto de filepond */
const onCaptureFile = (files) => {
  console.log('files --> ', files)
  attachFile.value = files
}

/* Limpiando el attach cuando se remueve o reemplaza el archivo */
const onRemoveFile = (files) => {
  clearInfo()
}

// Clear
const clearInfo = () => {
  attachFile.value = null
  listErrors.value = []
  listErrorsColumns.value = []
  listResults.value = []
  listColumns.value = []
  dataInventorySave.value = []
}

// Funcion para cargar archivo excel
const handleFileUpload = async () => {
  if (attachFile.value) {
    const reader = new FileReader()

    reader.onload = async (event) => {
      const result = event.target.result
      const data = new Uint8Array(result)
      const workbook = XLSX.read(data, { type: 'array' })
      const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]
      const json = XLSX.utils.sheet_to_json(firstWorksheet, { header: 1, raw: true })

      const general = [] // Array para los campos generales
      const attributes = [] // Array para los campos de las características

      console.log('json --> ', json)

      // Encuentra el índice del elemento 'Atributos' en la primera fila
      const attributesIndex = json[0].indexOf('Atributos')

      // Recorre el arreglo json y separa los campos generales de los campos de las características
      json[1].forEach((item, index) => {
        if (index < attributesIndex) {
          general.push(item)
        } else {
          attributes.push(item)
        }
      })

      const keys = json[1]
      const dataInventory = json.slice(2).map((values, rowIndex) => {
        const obj = {}
        keys.forEach((key, columnIndex) => {
          const columnLetter = columnToLetter(columnIndex + 1)
          obj[key] = {
            value: values[columnIndex] !== undefined ? values[columnIndex] : null,
            row: rowIndex + 3,
            column: columnLetter
          }
        })
        return obj
      })

      // Objeto de salida
      const output = {
        general,
        attributes,
        dataInventory
      }

      // Convertirlo a JSON
      const outputJson = JSON.stringify(output)

      await _InventoryService.value.validateColumnsTemplate({ data: outputJson }).then((res) => {
        console.log('Resultado --> ', res)

        if (res.data.success) {
          Swal.fire({
            title: '¡Éxito!',
            text: 'Los campos del archivo excel son correctos',
            icon: 'success',
            html: '<span style="font-size: 13px;">Visualizar el listado a importar</span>',
            confirmButtonText: 'Aceptar'
          })

          // Asignar los valores al listado
          listResults.value = res.data.dataInventoryShow
          listColumns.value = res.data.columns
          dataInventorySave.value = res.data.dataInventorySave
          listErrors.value = []
          listErrorsColumns.value = []
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Validación incorrecta',
            text: 'Los campos del archivo excel no son correctos',
            html: '<span style="font-size: 13px;">Visualizar los errores</span>'
          }).then(() => {
            listErrors.value = res.data.dataErrors
            listErrorsColumns.value = res.data.columns
            dataInventorySave.value = []
            listColumns.value = []
            listResults.value = []
          })
        }
      })
    }

    reader.readAsArrayBuffer(attachFile.value)
  } else {
    toast.add({ severity: 'error', summary: 'Validación', detail: 'Por favor seleccione un archivo', life: 3000 })
  }
}

// Funcion para importar registros
const onSubmit = async () => {
  if (dataInventorySave.value.length > 0) {
    Swal.fire({
      icon: 'info',
      title: '¿Esta seguro/a?',
      text: '¿Desea importar los registros de inventario?',
      showConfirmButton: true,
      confirmButtonColor: '#24963E',
      confirmButtonText: 'Si, Importar!',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, cancelar!'
    }).then(async (resp) => {
      if (resp.isConfirmed) {
        try {
          const response = await _InventoryService.value.importInventory({ data: JSON.stringify(dataInventorySave.value) })
          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              title: '¡Éxito!',
              text: 'Los registros de inventario se importaron correctamente',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              clearInfo()
              router.push({ name: 'daily-tech.inventario-ti.listado' })
            })
          }
        } catch (error) {
          console.log('Error al importar los registros de inventario: ', error.response)
          await Swal.fire({
            icon: 'error',
            title: 'Error',
            html: `Hubo un error al importar los registros de inventario <br> <span style="font-size: 13px;">${error.response.data.message ?? ''}</span> <br> <span style="font-size: 13px;">${error.response.data.details ?? ''}</span>`
          })
        }
      }
    })
  } else {
    toast.add({ severity: 'error', summary: 'Validación', detail: 'No hay registros para importar', life: 3000 })
  }
}
</script>

<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2">
    <template #end>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid gap-4 grid-cols-12 mt-5">
    <div class="intro-y col-span-12 lg:col-span-4">
      <div class="intro-y box h-full">
        <div class="flex flex-col sm:flex-row justify-between items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Información Plantilla</h2>
        </div>
        <div class="p-4 h-36 box flex items-center justify-center" style="height: 11rem; text-align:center;">
          <span class="text-sm">Visualizar información tipos de dispositivos, marcas, modelos, ubicaciones, propietarios y estados que actualmente se encuentran registrados en el sistema. Puede ver la información
            <a
              class="no-underline hover:underline text-indigo-600 cursor-pointer font-bold"
              @click="openModalInfo(0)"
            >aquí</a>
          </span>
        </div>
      </div>
    </div>
    <div class="intro-y col-span-12 lg:col-span-4">
      <div class="intro-y box h-full">
        <div class="flex flex-col sm:flex-row justify-between items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Descargar Plantilla Excel</h2>
        </div>
        <div class="p-4 h-36 box flex items-center justify-center" style="height: 11rem;">
          <Button label="Descargar Plantilla Excel" @click="handleDownloadXls()" icon="pi pi-table" class="p-button-warning p-button-sm" />
        </div>
      </div>
    </div>
    <div class="intro-y col-span-12 lg:col-span-4">
      <div class="intro-y box h-full">
        <div class="flex flex-col sm:flex-row justify-between items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Cargar e importar plantilla excel</h2>
        </div>
        <div class="p-4 flex flex-col" style="height: 11rem;">
          <FilePond ref="pondFile" @captureFile="onCaptureFile" @removeFile="onRemoveFile" />
          <Button
            label="Validar importación"
            icon="pi pi-upload"
            class="p-button-success p-button-sm"
            @click="handleFileUpload"
          />
        </div>

      </div>
    </div>

    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div class="flex flex-col sm:flex-row justify-between items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base">Información o resultados de la validación del archivo cargado.</h2>
        </div>
        <div class="p-4">
          <!-- Listado de datos a importar -->
          <DataTable
            v-if="listResults.length > 0 || listErrors.length === 0"
            showGridlines
            ref="dt"
            :value="listResults"
            class="p-datatable-sm text-sm"
            dataKey="id"
            :paginator="true"
            :rows="10"
            :filters="filters"
            sortField="id"
            :sortOrder="-1"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5,10,25,50]"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            responsiveLayout="scroll"
          >
            <template #header>
              <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg" :class="{'text-green-800': listResults.length > 0}">Listado de importación</h3>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>
            </template>

            <!-- Columnas -->
            <template #empty>
              <div class="flex flex-col items-center justify-center" style="height: 500px">
                <Icon icon="flat-color-icons:fine-print" class="text-gray-200" style="font-size: 15rem; opacity: 0.3"/>
                <span class="text-gray-400 text-2xl">Esperando por la validación del archivo a importar</span>
                <span class="text-gray-300">Cargue e importe el archivo en excel para visualizar la información o los errores de validación</span>
              </div>
            </template>
            <template #loading> Cargando la información, por favor espere... </template>
            <Column v-for="column in listColumns" :key="column.field" :field="column.field" :header="column.header" />
          </DataTable>

          <!-- Listado de errores -->
          <DataTable
            v-else
            showGridlines
            ref="dt"
            :value="listErrors"
            class="p-datatable-sm text-sm"
            dataKey="id"
            :paginator="true"
            :rows="10"
            :filters="filters"
            sortField="id"
            :sortOrder="-1"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5,10,25,50]"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            responsiveLayout="scroll"
          >
            <template #header>
              <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg text-red-800">Listado de errores</h3>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>
            </template>

            <!-- Columnas -->
            <template #empty>
              <div class="flex flex-col items-center justify-center" style="height: 500px">
                <Icon icon="flat-color-icons:warning" class="text-gray-200" style="font-size: 15rem; opacity: 0.3"/>
                <span class="text-gray-400 text-2xl">No se encontraron registros</span>
                <span class="text-gray-300">No hemos encontrado ningún elemento, pruebe con otro filtro u opción</span>
              </div>
            </template>
            <template #loading> Cargando la información, por favor espere... </template>
            <Column v-for="column in listErrorsColumns" :key="column.field" :field="column.field" :header="column.header" />
          </DataTable>
        </div>
      </div>
    </div>
  </div>

  <Button
    v-if="dataInventorySave.length > 0"
    label="Importar Registros"
    icon="pi pi-save"
    severity="success"
    size="small"
    class="p-button-raised p-button-success floating-button p-button-rounded"
    @click="onSubmit"
  />

  <ModalInfo ref="refModalInfo" />
</template>

<style scoped>
.floating-button {
  position: fixed !important;
  bottom: 30px;
  right: 40px;
  z-index: 1050;
}
</style>
